import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { StrokesValues } from "@find-truck-service/types/ui/stroke";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { checkboxSizes } from "@find-truck-service/ui/src/FtsCheckbox/FtsCheckboxInput";
const checkboxBoxInitialStyle = `
  display: flex;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  border-radius: ${Spacing["2xs"]};
`;
const borderActiveStyle = ({ checked, invalid }) => `
  border: ${StrokesValues.xs} solid ${ColorsValue[invalid ? "red-error-300" : checked ? "blue-accent-500" : "greyscale-100"]};
  &:focus, &:focus-visible { border: ${StrokesValues.sm} solid ${ColorsValue[invalid ? "red-error-300" : "blue-accent-300"]}; outline: none;}
`;
const borderDisabledStyle = (props) => `
  border: ${StrokesValues.xs} solid ${ColorsValue[props.checked ? "blue-accent-200" : "greyscale-50"]};
  &:focus, &:focus-visible { outline: none;}
`;
const backgroundActiveStyle = (props) => `
  background-color: ${ColorsValue[props.checked ? "blue-accent-500" : "greyscale-0"]};
`;
const backgroundDisabledStyle = (props) => `
  background-color: ${ColorsValue[props.checked ? "blue-accent-200" : "greyscale-0"]};
`;
const activeStyle = (props) => `
  ${backgroundActiveStyle(props)}
  ${borderActiveStyle(props)}
`;
const disabledStyle = (props) => `
  ${backgroundDisabledStyle(props)}
  ${borderDisabledStyle(props)}
  pointer-events: none;
  cursor: default;
`;
const checkboxBgStyle = (props) => (props.disabled ? disabledStyle(props) : activeStyle(props));
export const checkboxSizeStyle = (props) => `
  width: ${checkboxSizes[props.size]};
  height: ${checkboxSizes[props.size]};
`;
export const FtsCheckboxBox = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => !["size", "invalid", "checked"].includes(prop),
}) `
  ${checkboxBoxInitialStyle}
  ${checkboxSizeStyle}
  ${checkboxBgStyle}
`;
