import styled from "styled-components";
import { TextStyle } from "../FtsText";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { StrokesValues } from "@find-truck-service/types/ui/stroke";
import { defaultProps, defaultStyleProps } from "../default";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { InputSizes, InputStatuses, InputVariants } from "@find-truck-service/types/ui/input";
const inputProps = ["variant", "invalid", "size", "hasAppendIcon", "hasPrependIcon"];
const inputAndDefaultProps = [...inputProps, ...defaultProps];
const SPACING_WITH_SM_ICON = "36px";
const SPACING_WITH_MD_ICON = "44px";
const SPACING_WITH_SM_ICON_TEXT = "28px";
const SPACING_WITH_MD_ICON_TEXT = "32px";
const spacingStyleWithPrependAndAppendIcons = {
    [InputSizes.sm]: {
        [InputVariants.contained]: `padding: ${Spacing.sm} ${SPACING_WITH_SM_ICON} ${Spacing.sm} ${SPACING_WITH_SM_ICON}; `,
        [InputVariants.outlined]: `padding: ${Spacing.sm} ${SPACING_WITH_SM_ICON} ${Spacing.sm} ${SPACING_WITH_SM_ICON}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]} ${SPACING_WITH_SM_ICON_TEXT} ${Spacing["0"]} ${SPACING_WITH_SM_ICON_TEXT}; `,
    },
    [InputSizes.md]: {
        [InputVariants.contained]: `padding: ${Spacing.md} ${SPACING_WITH_MD_ICON} ${Spacing.md} ${SPACING_WITH_MD_ICON}; `,
        [InputVariants.outlined]: `padding: ${Spacing.md} ${SPACING_WITH_MD_ICON} ${Spacing.md} ${SPACING_WITH_MD_ICON}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]} ${SPACING_WITH_MD_ICON_TEXT} ${Spacing["0"]} ${SPACING_WITH_MD_ICON_TEXT}; `,
    },
};
const spacingStyleWithPrependIcon = {
    [InputSizes.sm]: {
        [InputVariants.contained]: `padding: ${Spacing.sm}  ${Spacing.sm} ${Spacing.sm} ${SPACING_WITH_SM_ICON}; `,
        [InputVariants.outlined]: `padding: ${Spacing.sm}  ${Spacing.sm} ${Spacing.sm} ${SPACING_WITH_SM_ICON}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]} ${Spacing["0"]} ${Spacing["0"]} ${SPACING_WITH_SM_ICON_TEXT}; `,
    },
    [InputSizes.md]: {
        [InputVariants.contained]: `padding: ${Spacing.md} ${Spacing.md} ${Spacing.md} ${SPACING_WITH_MD_ICON}; `,
        [InputVariants.outlined]: `padding: ${Spacing.md} ${Spacing.md} ${Spacing.md} ${SPACING_WITH_MD_ICON}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]} ${Spacing["0"]} ${Spacing["0"]} ${SPACING_WITH_MD_ICON_TEXT}; `,
    },
};
const spacingStyleWithAppendIcon = {
    [InputSizes.sm]: {
        [InputVariants.contained]: `padding: ${Spacing.sm} ${SPACING_WITH_SM_ICON} ${Spacing.sm} ${Spacing.sm}; `,
        [InputVariants.outlined]: `padding: ${Spacing.sm} ${SPACING_WITH_SM_ICON} ${Spacing.sm} ${Spacing.sm}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]} ${SPACING_WITH_SM_ICON_TEXT} ${Spacing["0"]} ${Spacing["0"]}; `,
    },
    [InputSizes.md]: {
        [InputVariants.contained]: `padding: ${Spacing.md} ${SPACING_WITH_MD_ICON} ${Spacing.md} ${Spacing.md}; `,
        [InputVariants.outlined]: `padding: ${Spacing.md} ${SPACING_WITH_MD_ICON} ${Spacing.md} ${Spacing.md}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]} ${SPACING_WITH_MD_ICON_TEXT} ${Spacing["0"]} ${Spacing["0"]}; `,
    },
};
const spacingStyleWithoutIcons = {
    [InputSizes.sm]: {
        [InputVariants.contained]: `padding: ${Spacing.sm}; `,
        [InputVariants.outlined]: `padding: ${Spacing.sm}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]}; `,
    },
    [InputSizes.md]: {
        [InputVariants.contained]: `padding: ${Spacing.md}; `,
        [InputVariants.outlined]: `padding: ${Spacing.md}; `,
        [InputVariants.text]: `padding: ${Spacing["0"]}; `,
    },
};
const spacingStyle = ({ hasPrependIcon, hasAppendIcon, size, variant }) => {
    if (hasPrependIcon && hasAppendIcon)
        return spacingStyleWithPrependAndAppendIcons[size][variant];
    if (hasPrependIcon)
        return spacingStyleWithPrependIcon[size][variant];
    if (hasAppendIcon)
        return spacingStyleWithAppendIcon[size][variant];
    return spacingStyleWithoutIcons[size][variant];
};
const disabledBgColors = {
    [InputVariants.contained]: ColorsValue["greyscale-50"],
    [InputVariants.outlined]: ColorsValue["greyscale-0"],
    [InputVariants.text]: ColorsValue["transparent"],
};
const inputBgColors = {
    [InputVariants.contained]: {
        [InputStatuses.default]: ColorsValue["greyscale-50"],
        [InputStatuses.hover]: ColorsValue["greyscale-50"],
        [InputStatuses.focus]: ColorsValue["greyscale-0"],
    },
    [InputVariants.outlined]: {
        [InputStatuses.default]: ColorsValue["greyscale-0"],
        [InputStatuses.hover]: ColorsValue["greyscale-0"],
        [InputStatuses.focus]: ColorsValue["greyscale-0"],
    },
    [InputVariants.text]: {
        [InputStatuses.default]: ColorsValue["transparent"],
        [InputStatuses.hover]: ColorsValue["transparent"],
        [InputStatuses.focus]: ColorsValue["transparent"],
    },
};
const inputInvalidBgColors = {
    [InputVariants.contained]: {
        [InputStatuses.default]: ColorsValue["red-error-50"],
        [InputStatuses.hover]: ColorsValue["red-error-50"],
        [InputStatuses.focus]: ColorsValue["greyscale-0"],
    },
    [InputVariants.outlined]: {
        [InputStatuses.default]: ColorsValue["greyscale-0"],
        [InputStatuses.hover]: ColorsValue["greyscale-0"],
        [InputStatuses.focus]: ColorsValue["greyscale-0"],
    },
    [InputVariants.text]: {
        [InputStatuses.default]: ColorsValue["transparent"],
        [InputStatuses.hover]: ColorsValue["transparent"],
        [InputStatuses.focus]: ColorsValue["transparent"],
    },
};
const inputBgStyles = (variant) => `
    background-color: ${inputBgColors[variant][InputStatuses.default]};
    &:hover { background-color: ${inputBgColors[variant][InputStatuses.hover]} !important; }
    &:focus, &:focus-visible { background-color: ${inputBgColors[variant][InputStatuses.focus]} !important; }
  `;
const inputInvalidBgStyles = (variant) => `
    background-color: ${inputInvalidBgColors[variant][InputStatuses.default]} !important;
    &:hover { background-color: ${inputInvalidBgColors[variant][InputStatuses.hover]} !important; }
    &:focus, &:focus-visible { background-color: ${inputInvalidBgColors[variant][InputStatuses.focus]} !important;}
  `;
const outlineColors = {
    [InputVariants.contained]: {
        [InputStatuses.default]: ColorsValue["greyscale-50"],
        [InputStatuses.hover]: ColorsValue["blue-accent-400"],
        [InputStatuses.focus]: ColorsValue["blue-accent-300"],
    },
    [InputVariants.outlined]: {
        [InputStatuses.default]: ColorsValue["greyscale-100"],
        [InputStatuses.hover]: ColorsValue["blue-accent-400"],
        [InputStatuses.focus]: ColorsValue["blue-accent-300"],
    },
    [InputVariants.text]: {
        [InputStatuses.default]: ColorsValue["transparent"],
        [InputStatuses.hover]: ColorsValue["transparent"],
        [InputStatuses.focus]: ColorsValue["transparent"],
    },
};
const outlineInvalidColors = {
    [InputVariants.contained]: {
        [InputStatuses.default]: ColorsValue["red-error-50"],
        [InputStatuses.hover]: ColorsValue["red-error-700"],
        [InputStatuses.focus]: ColorsValue["red-error-300"],
    },
    [InputVariants.outlined]: {
        [InputStatuses.default]: ColorsValue["red-error-300"],
        [InputStatuses.hover]: ColorsValue["red-error-700"],
        [InputStatuses.focus]: ColorsValue["red-error-300"],
    },
    [InputVariants.text]: {
        [InputStatuses.default]: ColorsValue["transparent"],
        [InputStatuses.hover]: ColorsValue["transparent"],
        [InputStatuses.focus]: ColorsValue["transparent"],
    },
};
const outlineStyle = (variant) => `
    outline: ${StrokesValues.xs} solid ${outlineColors[variant][InputStatuses.default]};
    &:hover { outline: ${StrokesValues.xs} solid ${outlineColors[variant][InputStatuses.hover]}; }
    &:focus, &:focus-visible { outline: ${StrokesValues.sm} solid ${outlineColors[variant][InputStatuses.focus]}; }
  `;
const invalidOutlineStyle = (variant) => `
    outline: ${StrokesValues.xs} solid ${outlineInvalidColors[variant][InputStatuses.default]};
    &:hover { outline: ${StrokesValues.xs} solid ${outlineInvalidColors[variant][InputStatuses.hover]}; }
    &:focus, &:focus-visible { outline: ${StrokesValues.sm} solid ${outlineInvalidColors[variant][InputStatuses.focus]}; }
  `;
const disabledOutlineColor = {
    [InputVariants.contained]: ColorsValue["greyscale-50"],
    [InputVariants.outlined]: ColorsValue["greyscale-50"],
    [InputVariants.text]: ColorsValue["transparent"],
};
const textVariantBySize = {
    [InputSizes.sm]: VariantsValues["text-3xs"],
    [InputSizes.md]: VariantsValues["text-2xs"],
};
const disabledStyle = ({ variant }) => `
  outline: ${Spacing["4xs"]} solid ${disabledOutlineColor[variant]};
  background-color: ${disabledBgColors[variant]}!important;
  path { stroke: ${ColorsValue["greyscale-50"]}; }
  color: ${ColorsValue["greyscale-300"]};
  pointer-events: none;
  cursor: default;
`;
const initialInputStyles = `
    width: 100%;
    border: none;
    cursor: text;
    border-radius: ${Spacing["2xs"]};
`;
const textOutlineInvalidStyle = () => `
  border-radius: 0;
  box-shadow: 0 ${StrokesValues.xs} 0 0 ${ColorsValue["transparent"]};
  &:hover { box-shadow: 0 ${StrokesValues.xs} 0 0 ${ColorsValue["red-brand-300"]}; }
  &:focus, &:focus-visible { box-shadow: 0 ${StrokesValues.sm} 0 0 ${ColorsValue["red-brand-400"]}; }
`;
const textOutlineDefaultStyle = () => `
  border-radius: 0;
  box-shadow: 0 ${StrokesValues.xs} 0 0 ${ColorsValue["transparent"]};
  &:hover { box-shadow: 0 ${StrokesValues.xs} 0 0 ${ColorsValue["blue-accent-400"]}; }
  &:focus, &:focus-visible { box-shadow: 0 ${StrokesValues.sm} 0 0 ${ColorsValue["blue-accent-300"]}; }
`;
const textOutlineStyle = ({ invalid, variant }) => {
    if (variant !== InputVariants.text)
        return "";
    return invalid ? textOutlineInvalidStyle : textOutlineDefaultStyle;
};
const inputOutlineStyle = ({ invalid, variant }) => {
    return invalid ? invalidOutlineStyle(variant) : outlineStyle(variant);
};
const inputBgStyle = ({ invalid, variant }) => {
    return invalid ? inputInvalidBgStyles(variant) : inputBgStyles(variant);
};
const textStyles = ({ size, disabled }) => `
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected,
    &:-webkit-autofill:active {
      ${TextStyle({
    weight: WeightSize.regular,
    variant: textVariantBySize[size],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})};
      color: ${ColorsValue["greyscale-800"]} !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    ${TextStyle({
    weight: WeightSize.regular,
    variant: textVariantBySize[size],
    color: ColorsValue["greyscale-800"],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})};

    &::placeholder {
      color: ${ColorsValue[disabled ? "greyscale-300" : "greyscale-500"]};
    };

    &::-webkit-input-placeholder {
      color: ${ColorsValue[disabled ? "greyscale-300" : "greyscale-500"]};
      opacity: 1;
    };
`;
export const FtsInput = styled.input.withConfig({
    shouldForwardProp: (prop) => !inputAndDefaultProps.includes(prop),
}) `
  ${defaultStyleProps}
  ${initialInputStyles}
  ${textStyles}
  ${spacingStyle}
  ${inputOutlineStyle}
  ${inputBgStyle}
  ${textOutlineStyle}
  ${({ disabled }) => (disabled ? disabledStyle : "")}
`;
