import { buttonBgColors } from "./buttonBgColors";
import { buttonIconColors } from "./buttonIconColors";
import { buttonLabelColors } from "./buttonLabelColors";
import { buttonStrokeColors } from "./buttonOutlineColors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { TextStyle } from "@find-truck-service/ui/src/FtsText";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ButtonDirections, ButtonSizes, ButtonStatuses, } from "@find-truck-service/types/ui/button";
export const initialButtonStyles = `
  border-radius: ${Spacing["2xs"]};
  transition: all .15s ease;
  justify-content: center;
  align-items: center;
  height: fit-content;
  position: relative;
  cursor: pointer;
  outline: unset;
  border: unset;
  display: flex;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const spacingStylesMdWithLabelRow = `
  padding: ${Spacing.md} ${Spacing["2xl"]};
`;
const spacingStylesMdWithLabelColumn = `
  padding: ${Spacing.md} ${Spacing.xl};
`;
const spacingStylesMdOnlyIcon = `
  padding: ${Spacing.md};
`;
const sizingStylesMd = `
  ${TextStyle({
    weight: WeightSize.semibold,
    variant: VariantsValues["text-2xs"],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})}
  svg { width: ${Spacing["2xl"]}; height: ${Spacing["2xl"]};}
`;
const spacingStylesSmWithLabelRow = `
  padding: ${Spacing.sm} ${Spacing.lg};
`;
const spacingStylesSmWithLabelColumn = `
  padding: ${Spacing.sm};
`;
const spacingStylesSmOnlyIcon = `
  padding: ${Spacing.sm};
`;
const sizingStylesSm = `
  ${TextStyle({
    weight: WeightSize.semibold,
    variant: VariantsValues["text-3xs"],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})}
  svg { width: ${Spacing.xl}; height: ${Spacing.xl};}
`;
const spacingStylesXsWithLabelRow = `
  padding: ${Spacing.xs} ${Spacing.sm};
`;
const spacingStylesXsWithLabelColumn = `
  padding: ${Spacing.xs} ${Spacing.sm};
`;
const spacingStylesXsOnlyIcon = `
  padding: ${Spacing.xs};
`;
const sizingStylesXs = `
  ${TextStyle({
    weight: WeightSize.regular,
    variant: VariantsValues["text-3xs"],
    lineHeightVariant: LineHeightVariantsValues.tight,
})}
  svg { width: ${Spacing.lg}; height: ${Spacing.lg};}
`;
const gapStyleXs = `
  row-gap: ${Spacing["3xs"]};
  column-gap: ${Spacing["2xs"]};
`;
const gapStyleSm = `
  row-gap: ${Spacing["3xs"]};
  column-gap: ${Spacing.xs};
`;
const gapStyleMd = `
  row-gap: ${Spacing["3xs"]};
  column-gap: ${Spacing.sm};
`;
export const gapStyles = {
    [ButtonSizes.md]: gapStyleMd,
    [ButtonSizes.sm]: gapStyleSm,
    [ButtonSizes.xs]: gapStyleXs,
};
export const spacingStylesWithLabel = {
    [ButtonSizes.md]: {
        [ButtonDirections.row]: spacingStylesMdWithLabelRow,
        [ButtonDirections.column]: spacingStylesMdWithLabelColumn,
    },
    [ButtonSizes.sm]: {
        [ButtonDirections.row]: spacingStylesSmWithLabelRow,
        [ButtonDirections.column]: spacingStylesSmWithLabelColumn,
    },
    [ButtonSizes.xs]: {
        [ButtonDirections.row]: spacingStylesXsWithLabelRow,
        [ButtonDirections.column]: spacingStylesXsWithLabelColumn,
    },
};
export const spacingStylesOnlyIcon = {
    [ButtonSizes.md]: spacingStylesMdOnlyIcon,
    [ButtonSizes.sm]: spacingStylesSmOnlyIcon,
    [ButtonSizes.xs]: spacingStylesXsOnlyIcon,
};
export const sizingStyles = {
    [ButtonSizes.md]: sizingStylesMd,
    [ButtonSizes.sm]: sizingStylesSm,
    [ButtonSizes.xs]: sizingStylesXs,
};
export const idleButtonStyle = (color, type) => `
  outline: ${Spacing["4xs"]} solid ${buttonStrokeColors[color][type][ButtonStatuses.idle]};
  path { stroke: ${buttonIconColors[color][type][ButtonStatuses.idle]}; }
  background: ${buttonBgColors[color][type][ButtonStatuses.idle]};
  color: ${buttonLabelColors[color][type][ButtonStatuses.idle]};
`;
export const focusButtonStyle = (color, type) => `
  outline: ${Spacing["3xs"]} solid ${buttonStrokeColors[color][type][ButtonStatuses.focus]};
  path { stroke: ${buttonIconColors[color][type][ButtonStatuses.focus]}; }
  background: ${buttonBgColors[color][type][ButtonStatuses.focus]};
  color: ${buttonLabelColors[color][type][ButtonStatuses.focus]};
`;
export const hoverButtonStyle = (color, type) => `
  outline: ${Spacing["4xs"]} solid ${buttonStrokeColors[color][type][ButtonStatuses.hover]};
  path { stroke: ${buttonIconColors[color][type][ButtonStatuses.hover]}; }
  background: ${buttonBgColors[color][type][ButtonStatuses.hover]};
  color: ${buttonLabelColors[color][type][ButtonStatuses.hover]};
`;
export const activeButtonStyle = (color, type) => `
  outline: ${Spacing["4xs"]} solid ${buttonStrokeColors[color][type][ButtonStatuses.active]};
  path { stroke: ${buttonIconColors[color][type][ButtonStatuses.active]}; }
  background: ${buttonBgColors[color][type][ButtonStatuses.active]};
  color: ${buttonLabelColors[color][type][ButtonStatuses.active]};
`;
export const disabledButtonStyle = (color, type) => `
  outline: ${Spacing["4xs"]} solid ${buttonStrokeColors[color][type][ButtonStatuses.disabled]};
  path { stroke: ${buttonIconColors[color][type][ButtonStatuses.disabled]}; }
  background: ${buttonBgColors[color][type][ButtonStatuses.disabled]};
  color: ${buttonLabelColors[color][type][ButtonStatuses.disabled]};
  pointer-events: none;
  cursor: default;
`;
export const loadingSvgAnimation = `
  svg { animation: spin 3s linear infinite; }
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;
