import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import React, { useCallback } from "react";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { Span } from "../../dist/ui/src/FtsText";
import { RichTextEditor } from "@find-truck-service/ui/src/components/react/RichTextEditor";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import {
  DESCRIPTION_CONTAINER_HEIGHT,
  DESCRIPTION_CONTAINER_WIDTH,
} from "@find-truck-service/ui/src/components/react/ListingItem/constants";

export const ListingDescriptionRow = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (value) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            description: value,
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          DESCRIPTION
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 8 }}>
        <RichTextEditor
          onChange={onChange}
          value={listingData.description}
          width={DESCRIPTION_CONTAINER_WIDTH}
          variant={VariantsValues["text-3xs"]}
          height={DESCRIPTION_CONTAINER_HEIGHT}
          placeholder="Write your business description/details here"
        />
      </FtsColumn>
    </FtsRow>
  );
};
