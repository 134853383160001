import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { checkboxSizes } from "@find-truck-service/ui/src/FtsCheckbox/FtsCheckboxInput";
const disabledStyle = `
  pointer-events: none;
  cursor: default;
`;
export const CheckboxLabelWrapper = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => prop !== "size" && prop !== "disabled",
}) `
  flex: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
  height: ${({ size }) => checkboxSizes[size]};
  ${({ disabled }) => (disabled ? disabledStyle : "")}
  padding-left: ${({ size }) => (size === CheckboxSizes.lg ? Spacing.sm : Spacing.xs)};
`;
