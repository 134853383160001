import React from "react";
const Dinersclub = (props) => {
    return (React.createElement("svg", { width: "23", height: "16", viewBox: "0 0 23 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("rect", { x: "0.5", y: "0.5", width: "22", height: "15", rx: "2", fill: "white", stroke: "#E5EAEF", ...props.pathProps }),
        React.createElement("path", { d: "M12.6366 12.9999C15.3063 13.013 17.7431 10.7708 17.7431 8.04325C17.7431 5.06072 15.3063 2.99889 12.6366 3H10.339C7.63745 2.99889 5.41357 5.06128 5.41357 8.04325C5.41357 10.7714 7.63745 13.013 10.339 12.9999H12.6366Z", fill: "#0079BE", ...props.pathProps }),
        React.createElement("path", { d: "M10.3499 3.41333C7.88099 3.41417 5.88071 5.46264 5.87988 7.99094C5.88071 10.519 7.88099 12.5674 10.3499 12.5683C12.819 12.5674 14.8201 10.519 14.8204 7.99094C14.8201 5.46264 12.8193 3.41417 10.3499 3.41333ZM7.51665 7.99094C7.51613 7.40638 7.68945 6.83525 8.01396 6.3522C8.33848 5.86915 8.79908 5.49666 9.33558 5.2834V10.6982C8.79904 10.4851 8.33838 10.1127 8.01385 9.62965C7.68932 9.14663 7.51603 8.5755 7.51665 7.99094ZM11.3638 10.6993V5.28312C11.9008 5.49608 12.3618 5.86854 12.6865 6.35175C13.0113 6.83496 13.1846 7.40639 13.1839 7.99122C13.1845 8.57603 13.0111 9.14741 12.6864 9.63059C12.3616 10.1138 11.9007 10.4863 11.3638 10.6993Z", fill: "white", ...props.pathProps }),
        React.createElement("path", { d: "M12.6366 12.9999C15.3063 13.013 17.7431 10.7708 17.7431 8.04325C17.7431 5.06072 15.3063 2.99889 12.6366 3H10.339C7.63745 2.99889 5.41357 5.06128 5.41357 8.04325C5.41357 10.7714 7.63745 13.013 10.339 12.9999H12.6366Z", fill: "#0079BE", ...props.pathProps }),
        React.createElement("path", { d: "M10.3499 3.41333C7.88099 3.41417 5.88071 5.46264 5.87988 7.99094C5.88071 10.519 7.88099 12.5674 10.3499 12.5683C12.819 12.5674 14.8201 10.519 14.8204 7.99094C14.8201 5.46264 12.8193 3.41417 10.3499 3.41333ZM7.51665 7.99094C7.51613 7.40638 7.68945 6.83525 8.01396 6.3522C8.33848 5.86915 8.79908 5.49666 9.33558 5.2834V10.6982C8.79904 10.4851 8.33838 10.1127 8.01385 9.62965C7.68932 9.14663 7.51603 8.5755 7.51665 7.99094ZM11.3638 10.6993V5.28312C11.9008 5.49608 12.3618 5.86854 12.6865 6.35175C13.0113 6.83496 13.1846 7.40639 13.1839 7.99122C13.1845 8.57603 13.0111 9.14741 12.6864 9.63059C12.3616 10.1138 11.9007 10.4863 11.3638 10.6993Z", fill: "white", ...props.pathProps })));
};
export default Dinersclub;
