import React from "react";
import { FtsCheckboxInput } from "../../../FtsCheckbox/FtsCheckboxInput";
import { TabIndexes } from "@find-truck-service/types/constants/tabIndexes";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { FtsCheckboxContainer } from "../../../FtsCheckbox/FtsCheckboxContainer";
import { CheckboxLabel } from "@find-truck-service/ui/src/FtsCheckbox/CheckboxLabel";
import { FtsCheckboxBox } from "@find-truck-service/ui/src/FtsCheckbox/FtsCheckboxBox";
import { FtsCheckboxIcon } from "@find-truck-service/ui/src/FtsCheckbox/FtsCheckboxIcon";
import { CheckboxErrorMsg } from "@find-truck-service/ui/src/FtsCheckbox/CheckboxErrorMsg";
import { FtsCheckboxWrapper } from "@find-truck-service/ui/src/FtsCheckbox/FtsCheckboxWrapper";
import { CheckboxLabelWrapper } from "@find-truck-service/ui/src/FtsCheckbox/CheckboxLabelWrapper";
const Checkbox = (props) => {
    const { name, size, label, checked, invalid, onClick, disabled, errorMessage, multipleSelect, ...defaultProps } = props;
    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            onClick && onClick();
        }
    };
    const onChangeHandle = () => {
        if (disabled)
            return;
        onClick && onClick();
    };
    return (React.createElement(FtsCheckboxContainer, { ...defaultProps },
        React.createElement(FtsCheckboxWrapper, { size: size },
            React.createElement(FtsCheckboxInput, { name: name, size: size }),
            React.createElement(FtsCheckboxBox, { size: size, checked: checked, disabled: disabled, onClick: onChangeHandle, onKeyDown: handleKeyDown, invalid: invalid || !!errorMessage, tabIndex: disabled ? TabIndexes.INACTIVE : TabIndexes.ACTIVE },
                !multipleSelect && React.createElement(FtsCheckboxIcon, { iconName: IconNames.Check, size: size }),
                multipleSelect && React.createElement(FtsCheckboxIcon, { iconName: IconNames.Minus, size: size })),
            !!label && (React.createElement(CheckboxLabelWrapper, { onClick: onChangeHandle, size: size, disabled: disabled },
                React.createElement(CheckboxLabel, { size: size, disabled: disabled }, label)))),
        !!errorMessage && React.createElement(CheckboxErrorMsg, { dangerouslySetInnerHTML: { __html: errorMessage } })));
};
export default Checkbox;
