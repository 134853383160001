import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span, TextStyle } from "@find-truck-service/ui/src/FtsText";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
const labelTextVariant = {
    [CheckboxSizes.sm]: VariantsValues["text-3xs"],
    [CheckboxSizes.md]: VariantsValues["text-2xs"],
    [CheckboxSizes.lg]: VariantsValues["text-2xs"],
};
const labelTextStyle = ({ size, disabled }) => `
  ${TextStyle({
    weight: WeightSize.regular,
    variant: labelTextVariant[size],
    lineHeightVariant: LineHeightVariantsValues.tight,
    color: ColorsValue[disabled ? "greyscale-300" : "greyscale-500"],
})}`;
export const CheckboxLabel = styled(Span).withConfig({
    shouldForwardProp: (prop) => prop !== "size" && prop !== "disabled",
}) `
  ${labelTextStyle}
`;
