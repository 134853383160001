import styled from "styled-components";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const FtsCheckboxWrapper = styled(FtsWrapper) `
  display: flex;
  user-select: none;
  position: relative;
  align-items: center;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
`;
