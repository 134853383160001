import React, { Component } from "react";

import { connect } from "react-redux";

import FTSModal from "../fts-modal";

import CloseButton from "../CloseButton";

import { createActivity } from "../../../redux/actions/activities";

import { Button, FormGroup } from "reactstrap";

import "../../../assets/scss/components/fts/listing-preview-modal.scss";

import Icon from "../Icons";

import {
  sendProof,
  updateProof,
  getProofById,
} from "../../../redux/actions/proofs";
import FTSAxios from "../../../axios/fts.instance";

import { addUserActivity } from "../../../redux/actions/user-activities";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import moment from "moment";

class ChangeListingRenewalDateModal extends Component {
  state = {
    isLoading: false,
    renewalDate: "",
  };

  dateRef = React.createRef();

  componentDidMount() {
    const proofToLoad = this.props.proofToLoad;
    if (proofToLoad) {
      this.props
        .getProofById(proofToLoad)
        .then(({ data }) => {
          this.setState({
            proofData: data.proof,
          });
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }

  closeModal = () => {
    this.props.toggleChangeListingRenewalDate();
    this.setState({
      isSendProofOpen: false,
    });
  };

  handleDateChange = (values) => {
    if (values)
      this.setState({ renewalDate: moment(values[0]).format("YYYY-MM-DD") });
  };
  onUpdate = async () => {
    try {
      console.log(this.state);
      const { listingId } = this.props;
      const { renewalDate } = this.state;
      this.setState({ isLoading: true });
      await FTSAxios.post("/admin/listings/adjust-renewal-date", {
        listingId: listingId,
        renewalDate,
      });
      this.props.getListingInfo(listingId);
      this.props.toggleChangeListingRenewalDate();
      toast.success("Listing renewal date updated successfully.");
    } catch (e) {
      console.log(e);
      toast.error(e.message || "Oops! Something went wrong. Please try again.");
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isOpen, title, onClose } = this.props;
    const { isLoading, renewalDate } = this.state;
    return (
      <>
        <FTSModal
          {...{ isOpen, title, onClose }}
          modalClasses={"fts-listing-proof-modal"}
          centered={true}
          size="md"
          footerCTA={() => {
            return (
              <div className="d-flex justify-content-between w-100 p-1">
                <div className="d-flex">
                  <Button
                    onClick={() => {
                      const seoUrlIdentifier =
                        this.props.listingData?.seoUrlIdentifier;
                      window.open(
                        `${process.env.REACT_APP_WEBSITE_URL}/page/${seoUrlIdentifier}`,
                        "_blank",
                      );
                    }}
                    color={"secondary"}
                    className="d-flex align-items-center mr-1"
                  >
                    <Icon name={"Eye"} />
                    <span className={"ml-1"}>View Vendor Page</span>
                  </Button>
                </div>{" "}
                <div className="d-flex">
                  <Button
                    disabled={!renewalDate || isLoading}
                    isLoading={isLoading}
                    onClick={this.onUpdate}
                    color={"primary"}
                    className="d-flex align-items-center"
                  >
                    <span>Update</span>
                  </Button>
                </div>
              </div>
            );
          }}
        >
          <div className="listing-proof-modal-body-wrapper p-1">
            <div className="d-flex justify-content-between mb-2">
              <h3>Change listing renewal date</h3>
              <div>
                <CloseButton onClick={this.closeModal} />
              </div>
            </div>

            <div>
              <FormGroup className="position-relative has-icon-right mb-0">
                <div className="fts-date-and-time">
                  <div
                    id="datepickerWrapperFrom"
                    className="u-datepicker input-group"
                  >
                    <Flatpickr
                      ref={this.dateRef}
                      placeholder="Renewal date"
                      className="fts-date-picker form-control date-picker"
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>

                <div className="form-control-position">
                  <Icon name="Calendar" size={17} />
                </div>
              </FormGroup>
            </div>
          </div>
        </FTSModal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  sendProof,
  createActivity,
  updateProof,
  getProofById,
  addUserActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeListingRenewalDateModal);
