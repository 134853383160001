import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
export const checkboxIconSizes = {
    [CheckboxSizes.sm]: SvgIconSizeValues.sm,
    [CheckboxSizes.md]: SvgIconSizeValues.md,
    [CheckboxSizes.lg]: SvgIconSizeValues.lg,
};
export const FtsCheckboxIcon = styled(FtsIcons).attrs((props) => ({
    svgProps: { width: checkboxIconSizes[props.size], height: checkboxIconSizes[props.size] },
    pathProps: { stroke: ColorsValue["greyscale-0"] },
})) ``;
