import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsButton } from "@find-truck-service/ui/src/FtsButton/FtsButton";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const Button = (props) => {
    const { iconName, children, ...rest } = props;
    return (React.createElement(FtsButton, { ...rest },
        props.loading && React.createElement(FtsIcons, { iconName: IconNames.Loader }),
        !!iconName && !props.loading && React.createElement(FtsIcons, { iconName: iconName }),
        !!props.label && props.loading ? "Loading" : props.label,
        children));
};
