export const Spacing = {
    "0": "0px",
    "4xs": "1px",
    "3xs": "2px",
    "2xs": "4px",
    xs: "6px",
    sm: "8px",
    md: "12px",
    lg: "16px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "32px",
    "4xl": "40px",
    xxl: "48px",
    "2xxl": "56px",
    "3xxl": "64px",
    "4xxl": "72px",
    "5xxl": "120px",
};
