import React from "react";
import ReactQuill from "react-quill";
import { InputLabel } from "../InputLabel";
export const RichTextEditor = (props) => {
    const { value, placeholder, onChange, label, required, readOnly, className, variant } = props;
    const height = props.height || "256px";
    const width = props.width || "100%";
    return (React.createElement(React.Fragment, null,
        React.createElement(InputLabel, { label: label, mb: "2xs", required: required }),
        React.createElement(ReactQuill, { theme: "snow", readOnly: readOnly, value: value ?? "", onChange: onChange, id: "rich_text_editor", placeholder: placeholder, style: { height, width }, className: `rich-text-editor ${className} ${variant}`, modules: { toolbar: [["bold", "italic", { list: "bullet" }, "clean"]] } })));
};
