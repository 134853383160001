import styled from "styled-components";
import { Span, TextStyle } from "@find-truck-service/ui/src/FtsText";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const CheckboxErrorMsg = styled(Span) `
  ${TextStyle({
    weight: WeightSize.regular,
    variant: VariantsValues["text-3xs"],
    lineHeightVariant: LineHeightVariantsValues.tight,
    color: ColorsValue["red-error-500"],
})}
`;
