import React, { Component } from "react";

import "../../../../assets/scss/components/fts/change-listing-type.scss";

import { connect } from "react-redux";
import { createActivity } from "../../../../redux/actions/activities";
import { setUpdateListingTypePayload } from "../../../../redux/actions/ads";
import { Button, Row, Col, FormGroup } from "reactstrap";
import PrimaryDevider from "../../primary-devider";
import TitleRow from "../../TitleRow";
import { MapPin, Phone } from "react-feather";
import moment from "moment";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import FTSDateAndTime from "../../date-picker";
import ListingTypePicker from "../../../../components/fts/listing-type-picker";
import { toast } from "react-toastify";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues } from "@find-truck-service/types/ui/text";

const updateDateFormat = "ddd, DD MMM YYYY, hh:mm a";
export const ftsDateFormat = "F j, Y";

export const opt = {
  1: { value: 1, label: "Premium ($498)", price: 498 },
  2: { value: 2, label: "Standard ($378)", price: 378 },
  3: { value: 3, label: "Basic Plus ($258)", price: 258 },
  4: { value: 4, label: "Basic ($138)", price: 138 },
  5: { value: 5, label: "Free ($0)", price: 0 },
};

const formSchema = Yup.object().shape({
  priceId: Yup.string().nullable().required("Listing type is required"),
  startAt: Yup.lazy((val) =>
    typeof val === "string"
      ? Yup.string().nullable().required("Start date is required")
      : Yup.array()
          .of(Yup.date())
          .nullable()
          .required("Start date is required"),
  ),
  endAt: Yup.lazy((val) =>
    typeof val === "string"
      ? Yup.string().nullable().required("End date is required")
      : Yup.array().of(Yup.date()).nullable().required("End date is required"),
  ),
});

class ChangeListingType extends Component {
  state = {
    inProgress: false,

    initialData: {
      priceId: null,
      startAt: null,
      endAt: null,
    },
  };

  handleSubmit = (values) => {
    const { startAt, endAt, priceId } = values;
    const { listingData } = this.props;

    let newStartAt = moment(startAt[0]);
    let newEndAt = moment(endAt[0]);
    if (newStartAt.isSame(newEndAt)) {
      this.formikRef.setFieldError(
        "endAt",
        "End date must be greater than start date",
      );
      return;
    }
    if (newEndAt.isBefore(newStartAt)) {
      this.formikRef.setFieldError(
        "startAt",
        "Start date must be less than end date",
      );
      return;
    }

    if (this.props.listingData.update) {
      toast.error("This listing is already has an update request.", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    this.props.handleSidebar(false);

    let newListingData = {
      ...listingData,
      renewalTimestamp: moment.unix(endAt[0]) / 1000000,
      priceId,
    };
    const updateListingTypePayload = {
      startAt,
      endAt,
      listingId: this.props.listingData?.id,
      oldListingType: this.props.listingData?.priceId,
      newListingType: priceId,
      reminderDate: moment
        .unix(this.props.listingData?.renewalTimestamp)
        .toISOString(),
      userId: this.props.listingData?.userId,
      newListingData,
    };

    this.props.setUpdateListingTypePayload(updateListingTypePayload);
    // then block

    if (values.priceId > this.props.listingData?.priceId) {
      this.props.toggleListingDowngradingModal(updateListingTypePayload);
    } else {
      this.props.handleReviewListingData(true);
      this.props.callToAction(
        "update_listing",
        false,
        {
          id: this.props.listingData?.id,
        },
        newListingData,
      );
    }
  };

  formatDate = (date) => {
    return moment(date).format(updateDateFormat);
  };

  formatNumber = (number, decimal = 2) => {
    if (!decimal) return new Intl.NumberFormat().format(number);
    return Number(number.toFixed(decimal))
      .toFixed(decimal)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  calculateAmount = ({ priceId, startAt, endAt }) => {
    if (priceId === 5) return;
    if (!priceId || !startAt || !endAt) return;
    const daysInYear = 365;
    const pricePerDay = opt[priceId].price / daysInYear;
    const dayToPay = moment(endAt[0]).diff(moment(startAt[0]), "days");

    return this.formatNumber(dayToPay * pricePerDay);
  };

  calculateCreditAmount = ({
    newPriceId,
    oldPriceId,
    renewalTimestamp,
    startAt,
    isItPaidFor,
  }) => {
    if (!newPriceId || !oldPriceId || !startAt || !renewalTimestamp) return;
    if (oldPriceId === 5 || !isItPaidFor) return 0;
    const daysInYear = moment()
      .startOf("year")
      .diff(moment().subtract(1, "year").startOf("year"), "day");
    const oldPricePerDay = opt[oldPriceId].price / daysInYear;
    const dayToPay = moment(moment.unix(renewalTimestamp)).diff(
      moment(),
      "days",
    );
    if (dayToPay <= 0) return 0;
    return this.formatNumber(dayToPay * oldPricePerDay);
  };

  render() {
    const { initialData } = this.state;
    const { listingData } = this.props;
    return (
      <div className="fts-change-listing-type-sidebar">
        <div className="fts-change-listing-type-sidebar-content">
          <div className="listing-details-card">
            <div className="d-flex flex-column">
              <PrimaryDevider text="CURRENT LISTING DETAILS" />
              <TitleRow
                title={" #" + listingData?.id + "-" + listingData?.name}
                className="listing-details-title"
              />
              <span className="listing-info-row">
                <span className="listing-location-info">
                  <MapPin size={20} /> {`${listingData?.city}`}
                  <span> </span>
                  {`, ${listingData?.state}`}
                </span>
                <Phone size={20} /> {listingData?.phoneNumber}
              </span>
              <Row>
                <Col>
                  <div className="listing-field-preview">
                    <div className="listing-field-preview-label">
                      Renewal date
                    </div>
                    <div className="listing-field-preview-value">
                      {this.formatDate(listingData?.renewalDate)}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="listing-field-preview">
                    <div className="listing-field-preview-label">
                      Created on
                    </div>
                    <div className="listing-field-preview-value">
                      {this.formatDate(listingData?.createdAt)}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="listing-field-preview">
                    <div className="listing-field-preview-label">
                      Last updated
                    </div>
                    <div className="listing-field-preview-value">
                      {this.formatDate(listingData?.updatedAt)}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="listing-field-preview">
                    <div className="listing-field-preview-label">
                      Listing type
                    </div>
                    <div className="listing-field-preview-value">
                      {opt[listingData?.priceId]?.label.toUpperCase()}
                    </div>
                  </div>
                </Col>
              </Row>
              <PrimaryDevider text="CHOOSE NEW LISTING TYPE AND DURATION" />
              <div className="listing-details-form">
                <Formik
                  innerRef={(ref) => {
                    this.formikRef = ref;
                  }}
                  validateOnChange={false}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={this.handleSubmit}
                  validationSchema={formSchema}
                  initialValues={initialData}
                >
                  {({ setFieldError, setFieldTouched, values }) => {
                    return (
                      <Form id="listing-details-form">
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Field name="priceId">
                                {({ meta, form, field: { name, value } }) => (
                                  <ListingTypePicker
                                    required
                                    label="Listing type "
                                    placeholder="Select listing type..."
                                    onOptionChange={(option) => {
                                      if (option) {
                                        setFieldError(name, undefined);
                                      }
                                      form.setFieldValue(
                                        name,
                                        option?.value || null,
                                      );
                                      setTimeout(() => {
                                        setFieldTouched(name, true, true);
                                      }, 0);
                                    }}
                                    {...{ meta, name, value }}
                                  />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Field name="startAt">
                                {({ meta, form, field }) => {
                                  return (
                                    <FTSDateAndTime
                                      placeholder="Select start date"
                                      label="Start date "
                                      options={{
                                        dateFormat: ftsDateFormat,
                                        minDate: new Date(),
                                      }}
                                      required
                                      {...{
                                        meta,
                                        form,
                                        field,
                                        setFieldError,
                                        setFieldTouched,
                                      }}
                                    />
                                  );
                                }}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Field name="endAt">
                                {({ meta, form, field }) => {
                                  return (
                                    <FTSDateAndTime
                                      label="End date "
                                      placeholder="Select end date"
                                      options={{
                                        dateFormat: ftsDateFormat,
                                        minDate: new Date(),
                                      }}
                                      required
                                      {...{
                                        meta,
                                        form,
                                        field,
                                        setFieldError,
                                        setFieldTouched,
                                      }}
                                    />
                                  );
                                }}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col
                            md={12}
                            className={
                              "d-flex justify-content-end align-items-end flex-column "
                            }
                          >
                            {values.priceId &&
                              values.startAt &&
                              values.endAt && (
                                <>
                                  <Span variant={VariantsValues["text-2xs"]}>
                                    Account credit amount:
                                    <b>
                                      $
                                      {this.calculateCreditAmount({
                                        newPriceId: values.priceId,
                                        oldPriceId: listingData.priceId,
                                        isItPaidFor: listingData.isItPaidFor,
                                        renewalTimestamp:
                                          listingData.renewalTimestamp,
                                        ...values,
                                      })}
                                    </b>
                                  </Span>
                                  <Span variant={VariantsValues["text-2xs"]}>
                                    Invoice amount:
                                    <b>${this.calculateAmount(values)}</b>
                                  </Span>
                                </>
                              )}
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="fts-change-listing-type-sidebar-footer">
          <div className="d-flex align-items-center">
            <Button
              onClick={() => {
                this.props.handleSidebar(false);
              }}
              color="tertiary"
              className="mr-1"
            >
              Cancel
            </Button>

            <Button color="primary" form="listing-details-form" type="submit">
              Next Step: Review Listing details
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  createActivity,
  setUpdateListingTypePayload,
})(ChangeListingType);
