import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
export const checkboxSizes = {
    [CheckboxSizes.sm]: Spacing.xl,
    [CheckboxSizes.md]: Spacing["2xl"],
    [CheckboxSizes.lg]: Spacing["3xl"],
};
export const checkboxSizeStyle = (props) => `
  width: ${checkboxSizes[props.size]};
  height: ${checkboxSizes[props.size]};
`;
export const FtsCheckboxInput = styled.input
    .withConfig({
    shouldForwardProp: (prop) => !["size"].includes(prop),
})
    .attrs({
    type: "checkbox",
    disabled: true,
}) `
  margin: 0;
  opacity: 0;
  position: absolute;
  tab-index: none;
  pointer-events: none;
  ${checkboxSizeStyle}
`;
