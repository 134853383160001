import { FtsSideOutWrapperFooter } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperFooter";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "../dist/types/ui/button";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import { closeSideOutAction } from "../../../redux/actions/v2/sideOut";
import {
  getListingDataByIdSuccess,
  setListingInvalidFormFields,
  updateListingDataByIdRequest,
  updateListingPutOnHoldByIdRequest,
} from "../../../redux/actions/v2/listings";
import { Dropdown } from "../dist/ui/src/components/react/Dropdown";
import onHoldOptions from "../../../data/on-hold-options";
import { FtsColumn } from "../dist/ui/src/FtsColumn/FtsColumn";
import { listingDetailsRowValidator } from "../EditListingForm/Validators/listingDetailsRowValidator";
import { listingContactRowValidator } from "../EditListingForm/Validators/listingContactRowValidator";
import { selectSideOutOnSuccess } from "../../../redux/reducers/v2/sideOut";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const SideOutEditListingFooter = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const onSuccess = useSelector(selectSideOutOnSuccess);

  const hideSidebar = useCallback(() => {
    dispatch(closeSideOutAction());
  }, [closeSideOutAction]);

  const updateListing = useCallback(async () => {
    try {
      await listingDetailsRowValidator.validate(listingData);
      await listingContactRowValidator.validate(listingData);
      dispatch(setListingInvalidFormFields({ data: null }));
      dispatch(updateListingDataByIdRequest({ data: listingData, onSuccess }));
    } catch (e) {
      if (e.name === "ValidationError") {
        dispatch(
          setListingInvalidFormFields({ data: { [e.path]: e.errors[0] } }),
        );
        console.log(e);
      }
    }
  }, [listingData]);

  const handlePutOnHold = useCallback((option) => {
    if (option.value) {
      dispatch(
        updateListingPutOnHoldByIdRequest({
          onSuccess,
          data: {
            onHold: option.value,
          },
        }),
      );
    }
  }, []);

  const handleChangeUpdateDateCheck = useCallback(() => {
    dispatch(
      getListingDataByIdSuccess({
        data: {
          ...listingData,
          doNotChangeUpdateDate: !listingData.doNotChangeUpdateDate,
        },
      }),
    );
  }, [listingData]);

  return (
    <FtsSideOutWrapperFooter py={"lg"} px={"3xl"} columnGap={"lg"}>
      <FtsColumn size={{ xs: 3 }}>
        <Dropdown
          size={"sm"}
          menuPlacement={"top"}
          options={onHoldOptions}
          name={"Put on hold"}
          onChange={handlePutOnHold}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 4 }} align={"center"} display={"flex"}>
        <Checkbox
          size={CheckboxSizes.sm}
          onClick={handleChangeUpdateDateCheck}
          checked={listingData.doNotChangeUpdateDate}
          label={"Don’t change update date"}
        />
      </FtsColumn>

      <FtsColumn size={{ xs: 5 }}>
        <FtsWrapper display={"flex"} align={"center"} justify={"flex-end"}>
          <Button
            onClick={hideSidebar}
            type={ButtonTypes.text}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            mr={"lg"}
            label={"Cancel"}
          />

          <Button
            onClick={updateListing}
            type={ButtonTypes.contained}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            label={"Update listing"}
          />
        </FtsWrapper>
      </FtsColumn>
    </FtsSideOutWrapperFooter>
  );
};
