export const IMAGE_SIZES = {
    1: { width: 295, height: 245 },
    2: { width: 295, height: 130 },
    3: { width: 295, height: 65 },
};
export const FREE_LISTING_PRICE_ID = 5;
export const BASIC_LISTING_PRICE_ID = 4;
export const PREMIUM_LISTING_PRICE_ID = 1;
export const DESCRIPTION_WIDTH = "491px";
export const DESCRIPTION_CONTAINER_WIDTH = "525px";
export const DESCRIPTION_CONTAINER_HEIGHT = "314px";
