import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { Input } from "../../dist/ui/src/components/react/Input";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import { Divider } from "../../dist/ui/src/components/react/Divider";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";
import { PhoneNumberInput } from "../../dist/ui/src/components/react/PhoneNumberInput";
import useCheckboxValidation from "../hooks/useCheckboxValidation";
import { Span } from "../../dist/ui/src/FtsText";
import isObject from "lodash/isObject";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import {
  additionalOptIn,
  checkboxOptLabels,
  mobileOptIn,
  primaryOptIn,
  renewalOptIn,
} from "../constants";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import {
  selectAccountData,
  selectAccountInvalidFormFields,
} from "../../../../redux/reducers/v2/account";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const AccountContactRow = () => {
  const invalidFormFields = useSelector(selectAccountInvalidFormFields);
  const accountData = useSelector(selectAccountData) || {};
  const dispatch = useDispatch();

  const excludeRenewal = accountData.excludeRenewalEmails;
  const isMobileValid = useCheckboxValidation("mobilePhone", mobileOptIn);
  const isEmailValid = useCheckboxValidation("email", primaryOptIn);
  const isAdditionalEmailValid = useCheckboxValidation(
    "additionalEmail",
    additionalOptIn,
  );

  useEffect(() => {
    if (!excludeRenewal) return;
    if (!renewalOptIn.find((name) => accountData[name])) return;
    let mappedAccountData = { ...accountData };
    renewalOptIn.forEach((name) => (mappedAccountData[name] = false));
    dispatch(setAccountDataAction(mappedAccountData));
  }, [accountData]);

  const onChange = useCallback(
    (event) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          [event.target.name]: event.target.value,
        }),
      );
    },
    [accountData],
  );

  const handleCheckboxChange = useCallback(
    (name, value, category) => {
      if (excludeRenewal && renewalOptIn.find((n) => n === name)) return;
      if (category === "mobilePhone" && !isMobileValid) return;
      if (category === "email" && !isEmailValid) return;
      if (category === "additionalEmail" && !isAdditionalEmailValid) return;
      dispatch(setAccountDataAction({ ...accountData, [name]: !value }));
    },
    [
      isMobileValid,
      isEmailValid,
      isAdditionalEmailValid,
      excludeRenewal,
      accountData,
    ],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          CONTACT
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <PhoneNumberInput
          name={"phoneNumber"}
          value={accountData.phoneNumber}
          onChange={onChange}
          label={"Phone (primary)"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["phoneNumber"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <PhoneNumberInput
          name={"phoneNumberSecondary"}
          value={accountData.phoneNumberSecondary}
          onChange={onChange}
          label={"Phone (secondary)"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) &&
            invalidFormFields["phoneNumberSecondary"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <PhoneNumberInput
          name={"mobilePhone"}
          value={accountData.mobilePhone}
          onChange={onChange}
          label={"Mobile"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["mobilePhone"]
          }
        />
      </FtsColumn>

      <FtsColumn size={{ xs: 6 }}>
        <FtsWrapper mb={"2xs"}>
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
          >
            Opt-in
          </Span>
        </FtsWrapper>
        <FtsRow columnGap={"lg"} py={"xs"}>
          {mobileOptIn.map((name, index) => (
            <FtsColumn size={{ xs: 4 }} key={name}>
              <Checkbox
                size={CheckboxSizes.sm}
                label={checkboxOptLabels[index]}
                checked={!!accountData[name]}
                onClick={() =>
                  handleCheckboxChange(name, accountData[name], "mobilePhone")
                }
              />
            </FtsColumn>
          ))}
        </FtsRow>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <Divider />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"email"}
          value={accountData.email}
          onChange={onChange}
          label={"Email"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["email"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <FtsWrapper mb={"2xs"}>
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
          >
            Opt-in
          </Span>
        </FtsWrapper>
        <FtsRow columnGap={"lg"} py={"xs"}>
          {primaryOptIn.map((name, index) => (
            <FtsColumn size={{ xs: 4 }} key={name}>
              <Checkbox
                size={CheckboxSizes.sm}
                label={checkboxOptLabels[index]}
                checked={!!accountData[name]}
                onClick={() =>
                  handleCheckboxChange(name, accountData[name], "email")
                }
              />
            </FtsColumn>
          ))}
        </FtsRow>
      </FtsColumn>

      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"additionalEmail"}
          value={accountData.additionalEmail}
          onChange={onChange}
          label={"Additional Email"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["additionalEmail"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <FtsWrapper mb={"2xs"}>
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
          >
            Opt-in
          </Span>
        </FtsWrapper>
        <FtsRow columnGap={"lg"} py={"xs"}>
          {additionalOptIn.map((name, index) => (
            <FtsColumn size={{ xs: 4 }} key={name}>
              <Checkbox
                size={CheckboxSizes.sm}
                label={checkboxOptLabels[index]}
                checked={!!accountData[name]}
                onClick={() =>
                  handleCheckboxChange(
                    name,
                    accountData[name],
                    "additionalEmail",
                  )
                }
              />
            </FtsColumn>
          ))}
        </FtsRow>
      </FtsColumn>
    </FtsRow>
  );
};
