import React from "react";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { FtsAccordionContainer } from "../../../FtsAccordion/FtsAccordionContainer";
import { LineHeightVariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { FtsAccordionSummary } from "@find-truck-service/ui/src/FtsAccordion/FtsAccordionSummary";
import { FtsAccordionContent } from "@find-truck-service/ui/src/FtsAccordion/FtsAccordionContent";
import { FtsAccordionRotateIcon } from "@find-truck-service/ui/src/FtsAccordion/FtsAccordionRotateIcon";
export const Accordion = (props) => {
    const { iconName, children, title, subtitle, show, disabled, onClick, ...defaultProps } = props;
    return (React.createElement(FtsAccordionContainer, { ...defaultProps },
        React.createElement(FtsAccordionSummary, { show: show, disabled: disabled, onClick: onClick },
            React.createElement(FtsWrapper, { display: "flex", gap: "md", align: "center" },
                iconName && React.createElement(FtsIcons, { iconName: iconName }),
                React.createElement(FtsWrapper, { display: "flex", gap: "3xs", direction: "column", align: "flex-start" },
                    title && (React.createElement(Span, { variant: "text-2xs", weight: WeightSize.medium, color: ColorsValue["greyscale-800"], lineHeightVariant: LineHeightVariantsValues.relaxed }, title)),
                    subtitle && (React.createElement(Span, { color: ColorsValue["greyscale-300"], variant: "text-4xs", weight: WeightSize.regular }, subtitle)))),
            React.createElement(FtsAccordionRotateIcon, { show: show },
                React.createElement(FtsIcons, { iconName: IconNames.ChevronDown }))),
        React.createElement(FtsAccordionContent, { show: show }, show && children)));
};
