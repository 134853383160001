import React from "react";
const Jcb = (props) => {
    return (React.createElement("svg", { width: "24", height: "16", viewBox: "0 0 24 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("g", { id: "Size=1-sm" },
            React.createElement("rect", { id: "Base", x: "0.5", y: "0.5", width: "23", height: "15", rx: "2", fill: "white", stroke: "#E5EAEF", ...props.pathProps }),
            React.createElement("g", { id: "jcb-logo" },
                React.createElement("path", { id: "Path", d: "M16.0498 9.29001H17.1998H17.3498C17.5902 9.24115 17.7596 9.02511 17.7498 8.78001C17.7527 8.53507 17.5873 8.32008 17.3498 8.26001H16.0498L16.0498 9.29001Z", fill: "#40A648", ...props.pathProps }),
                React.createElement("path", { id: "Path_2", d: "M17.07 2C15.9655 2 15.07 2.89543 15.07 4V6.08H18.07C18.71 6.08 19.18 6.44 19.18 7.01C19.1694 7.50812 18.7682 7.9094 18.27 7.92V8C18.92 8 19.41 8.4 19.41 9C19.41 9.6 18.86 10 18.14 10H15.04V14H18C19.1046 14 20 13.1046 20 12V2H17.07Z", fill: "#40A648", ...props.pathProps }),
                React.createElement("path", { id: "Path_3", d: "M17.6098 7.14999C17.6117 6.91215 17.4357 6.71035 17.1998 6.67999H16.0498V7.67999H17.1998C17.4373 7.645 17.6124 7.44 17.6098 7.19999V7.14999Z", fill: "#40A648", ...props.pathProps }),
                React.createElement("path", { id: "Path_4", d: "M6 2C4.89543 2 4 2.89543 4 4V8.93C4.53064 9.21145 5.11951 9.36551 5.72 9.38C5.99712 9.39945 6.26984 9.30274 6.47281 9.11305C6.67577 8.92337 6.79068 8.6578 6.79 8.38V6.07H8.52V8.38C8.52 9.29 8 10 6.06 10C5.38577 9.99638 4.71437 9.91245 4.06 9.75V14H6.94C7.47043 14 7.97914 13.7893 8.35421 13.4142C8.72929 13.0391 8.94 12.5304 8.94 12V2H6Z", fill: "#0D67B0", ...props.pathProps }),
                React.createElement("path", { id: "Path_5", d: "M11.54 2C10.4355 2 9.54004 2.89543 9.54004 4V6.62C10.3674 6.07191 11.3689 5.85093 12.35 6C12.8795 6.03262 13.405 6.11295 13.92 6.24V7.09C13.4506 6.8366 12.9323 6.68658 12.4 6.65C11.32 6.57 10.68 7.1 10.68 8.02C10.68 8.94 11.32 9.48 12.4 9.39C12.9314 9.34874 13.4488 9.19899 13.92 8.95V9.8C13.4054 9.92911 12.8797 10.0095 12.35 10.04C11.3664 10.1832 10.3646 9.95507 9.54004 9.4V14H12.48C13.5846 14 14.48 13.1046 14.48 12V2H11.54Z", fill: "#BE2034", ...props.pathProps })))));
};
export default Jcb;
