import { Field } from "formik";
import React, { Component, memo } from "react";
// import { Edit, Trash2 } from "react-feather";
import { Button, Col, FormGroup, Row, Spinner, CustomInput } from "reactstrap";
import { Phone, MapPin } from "react-feather";

import Divider from "../../../../../components/fts/Divider";
import FormikInput from "../../../../../components/fts/formik/formik-fast-input";
import TitleRow from "../../../../../components/fts/TitleRow";

import FormikConfirmActivation from "../../../../../components/fts/formik-confirm-activation";
import FormikConfirmSwitch from "../../../../../components/fts/formik-confirm-switch";
import StatesPicker from "../../../../../components/fts/states-picker";
import FTSDateAndTime from "../../../../../components/fts/date-picker";
import ListingTypePicker from "../../../../../components/fts/listing-type-picker";

import FormikMaskedInput from "../../../../../components/fts/formik/masked-input";
import { zipCodeMask } from "../../../../../utility/masks";
import Icon from "../../../../../components/fts/Icons";
import AccountBox from "../../../../../components/fts/account-box-component";
import OnHoldPicker from "../../../../../components/fts/on-hold-picker";
import FTSMenu from "../../../../../components/fts/fts-menu";
import { toast } from "react-toastify";
import { checkPermissions } from "../../../../../utility/permissionsCheck";
import UpgradeInProgressModal from "../../../../../components/fts/upgrade-in-progress-modal";

class ListingInfo extends Component {
  state = {
    priceID: null,
    isOpen: false,
    isOpenUpgradeInProgressModal: false,
  };

  options = [
    {
      id: "changeListingType",
      componentLeft: <Icon name="Upgrade" />,
      text: "Change listing type",
      action: () => {
        this.handleMenu();
        this.props.callToAction("change_listing_type", false, null);
      },
      disabled: this.props.listingData?.update,
      hide: !checkPermissions(
        [{ id: 4, subCat: [76] }],
        this.props.adminPermissions,
      ),
    },
    {
      id: "changeListingRenewalDate",
      componentLeft: <Icon name="Upgrade" />,
      text: "Change listing renewal date",
      action: () => {
        this.handleMenu();
        this.props.toggleChangeListingRenewalDate();
      },
      hide: !checkPermissions(
        [{ id: 4, subCat: [83] }],
        this.props.adminPermissions,
      ),
    },
    {
      id: "sendListingProof",
      componentLeft: <Icon name="Proof" />,
      text: "Send Listing proof",
      action: () => {
        this.handleMenu();
        this.props.setListingPreviewModalTitle("sendListingProof");
        this.props.toggleListingPreviewModal();
      },
    },
    {
      id: "previewListing",
      componentLeft: <Icon name="Preview" />,
      text: "Preview listing",
      action: () => {
        this.handleMenu();
        this.props.setListingPreviewModalTitle("sendListingProof");
        this.props.toggleListingPreviewModal();
      },
    },
    {
      id: "duplicateListing",
      componentLeft: <Icon name="Duplicate" />,
      text: "Duplicate listing",
      action: () => {
        //   this.props.setActiveDropdown(null);
        this.handleMenu();
        this.props.toggleDuplicateListingModal();
      },
    },
    {
      id: "updateHistory",
      componentLeft: <Icon name="UpdatesMenuItem" />,
      text: "Update history",
      action: () => {
        this.handleMenu();
        this.props.callToAction("listing_update_history", false, null);
      },
    },
    {
      id: "reassignListing",
      componentLeft: <Icon name="Reassign" />,
      text: "Reassign listing",
      action: () => {
        this.handleMenu();
        this.props.toggleReassignListingModal();
      },
    },
    {
      id: "requestUpdate",
      componentLeft: <Icon name="EditAction" />,
      text: "Request update",
      action: this.props.onRequestUpdate,
    },
    {
      id: "removeListing",
      componentLeft: <Icon name="Trash" />,
      text: "Remove listing",
      red: true,
      action: this.props.onRemoveListing,
      disabled: !checkPermissions(
        [{ id: 4, subCat: [53] }],
        this.props.adminPermissions,
      ),
    },
  ];

  handleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  componentDidMount() {
    let { adminPermissions, listingData } = this.props;
    let havePermissionForFeaturedAuthorizedVendorToggle = checkPermissions(
      [{ id: 4, subCat: [43] }],
      adminPermissions,
    );
    let havePermissionForAuthorizedVendorToggle = checkPermissions(
      [{ id: 4, subCat: [42] }],
      adminPermissions,
    );
    let havePermissionForAutomaticDowngradesToggle = checkPermissions(
      [{ id: 4, subCat: [40] }],
      adminPermissions,
    );
    let havePermissionForRenewalEmailsToggle = checkPermissions(
      [{ id: 4, subCat: [39] }],
      adminPermissions,
    );

    let havePermissionForApplyToMultiple = checkPermissions(
      [{ id: 4, subCat: [38] }],
      adminPermissions,
    );

    window.scrollTo(0, 0);
    this.setState({
      priceID: listingData?.priceId,
      havePermissionForApplyToMultiple,
      havePermissionForFeaturedAuthorizedVendorToggle,
      havePermissionForAuthorizedVendorToggle,
      havePermissionForAutomaticDowngradesToggle,
      havePermissionForRenewalEmailsToggle,
    });
  }

  handlePriceIdChange = (priceID) => {
    this.setState({
      priceID: priceID,
    });
  };

  toggleUpgradeInProgressModal = () => {
    this.setState({
      isOpenUpgradeInProgressModal: !this.state.isOpenUpgradeInProgressModal,
    });
  };

  render() {
    const {
      createMode,
      editMode,
      inProgress,
      onEditToggle,
      resetForm,
      // onRemoveListing,
      // onPreviewListing,
      onPrefillForm,
      setFieldTouched,
      setFieldError,
      // validForm,
      // validForm,
      listingData,
      accountInfo,
      updateListingFromEmail,
      updateListingSidebar,
      preview,
      keys,
      changedSections,
      right,
      toggleEditConfirmationModal,
      toggleMultipleListingsModal,
      saveUpdatePayload,
      values,
      listingLocked,
      upgradeInProgress,
    } = this.props;

    if (listingData?.isDeleted) {
      this.options[4] = {
        id: "enableListing",
        componentLeft: <Icon name="Update" />,
        text: "Enable listing",
        action: this.props.onRemoveListing,
      };
    }
    const {
      priceID,
      havePermissionForFeaturedAuthorizedVendorToggle,
      havePermissionForAuthorizedVendorToggle,
      havePermissionForAutomaticDowngradesToggle,
      havePermissionForRenewalEmailsToggle,
      havePermissionForApplyToMultiple,
      isOpenUpgradeInProgressModal,
    } = this.state;
    // const iconProps = {
    //   size: 20,
    // };

    /********************************
     *
     * ABOUT LISTING FIELDS
     *
     ********************************/

    const priceId = (
      <FormGroup>
        <Field name="priceId">
          {({ meta, form, field: { name, value } }) => (
            <span
              className={`${
                keys && keys.includes(name.split(".").pop()) && "field-changed"
              }`}
            >
              <ListingTypePicker
                isDisabled={updateListingFromEmail || !createMode}
                label="Listing type"
                required={createMode}
                placeholder="Select listing type..."
                onOptionChange={(option) => {
                  if (option) {
                    setFieldError(name, undefined);
                  }
                  this.setState({
                    priceID: option?.value,
                  });
                  form.setFieldValue(name, option);
                  setTimeout(() => {
                    setFieldTouched(name, true, true);
                  }, 0);
                }}
                {...{ meta, name, value }}
              />
            </span>
          )}
        </Field>
      </FormGroup>
    );

    const renewalDate = (
      <FormGroup>
        <Field name="renewalDate">
          {({ meta, form, field, name }) => {
            let date = new Date();
            date.setDate(date.getDate() + 366);
            if (!meta.value || !meta.value.length) {
              meta.initialValue = date.toISOString();
            } else {
              meta.initialValue = Array.isArray(meta.value)
                ? new Date(meta.value[0]).toISOString()
                : meta.value;
            }

            if (
              listingData?.renewalTimestamp ||
              listingData?.renewalLaterDate
            ) {
              date = new Date(listingData?.renewalTimestamp * 1000);
              meta.initialValue = date.toISOString();
            }

            return (
              <span
                className={`${
                  keys &&
                  keys.includes(name.split(".").pop()) &&
                  "field-changed"
                }`}
              >
                <FTSDateAndTime
                  placeholder="Select date"
                  label="Renewal date"
                  className="disabled"
                  disabled={!createMode}
                  options={{
                    minDate: new Date(),
                    dateFormat: "M d, Y",
                  }}
                  {...{ meta, form, field, setFieldTouched, setFieldError }}
                />
              </span>
            );
          }}
        </Field>
      </FormGroup>
    );

    const createdAt = (
      <span
        className={`${keys && keys.includes("createdAt") && "field-changed"}`}
      >
        <FormikInput
          disabled
          name="createdAt"
          label={updateListingFromEmail ? "First listed" : "Created On"}
          labelId="createdOn"
        />
      </span>
    );

    /********************************
     *
     * ABOUT COMPANY FIELDS
     *
     ********************************/

    const statesPicker = (
      <FormGroup>
        <Field name="addressInfo.state">
          {({
            meta,
            form: { setFieldValue, setFieldError, setFieldTouched },
            field: { onBlur, name, value },
          }) => (
            <span
              className={`${
                keys && keys.includes(name.split(".").pop()) && "field-changed"
              }`}
            >
              <StatesPicker
                isDisabled={!editMode}
                label="State"
                required
                placeholder="Select state..."
                onStateChange={(option) => {
                  if (option) {
                    setFieldError(name, undefined);
                  }
                  setFieldValue(name, option);
                  setTimeout(() => {
                    setFieldTouched(name, true, true);
                  }, 0);
                }}
                {...{ meta, onBlur, name, value }}
              />{" "}
            </span>
          )}
        </Field>
      </FormGroup>
    );

    const adminStatusPicker = (
      <FormGroup>
        <span
          className={`${keys && keys.includes("status") && "field-changed"}`}
        >
          <FormikConfirmActivation
            name="status"
            label="Status"
            required
            isDisabled={!editMode}
            {...{ setFieldError, setFieldTouched }}
          />
        </span>
      </FormGroup>
    );

    const companyName = (
      <span className={`${keys && keys.includes("name") && "field-changed"}`}>
        <FormikInput
          disabled={!editMode}
          name="name"
          label="Company Name"
          required
          labelId="name"
          placeholder="Enter company name"
        />
      </span>
    );

    const address = (
      <span
        className={`${keys && keys.includes("address") && "field-changed"}`}
      >
        <FormikInput
          disabled={!editMode}
          name="addressInfo.address"
          label="Address"
          required
          labelId="addressInfo.address"
          placeholder="Enter street address"
        />
      </span>
    );

    const city = (
      <span className={`${keys && keys.includes("city") && "field-changed"}`}>
        <FormikInput
          disabled={!editMode}
          name="addressInfo.city"
          label="City"
          required
          labelId="addressInfo.city"
          placeholder="Enter city name"
        />
      </span>
    );

    const zip = (
      <span className={`${keys && keys.includes("zip") && "field-changed"}`}>
        <FormikMaskedInput
          disabled={!editMode}
          name="addressInfo.zip"
          label="Zip Code"
          required
          labelId="addressInfo.zip"
          placeholder="Enter zip code"
          mask={zipCodeMask}
        />
      </span>
    );

    const interstate = (
      <span
        className={`${keys && keys.includes("interstate") && "field-changed"}`}
      >
        <FormikInput
          disabled={!editMode}
          name="interstate"
          label="Interstate"
          labelId="interstate"
          placeholder="Enter interstate"
        />
      </span>
    );

    const mileMarker = (
      <span
        className={`${keys && keys.includes("mileMarker") && "field-changed"}`}
      >
        <FormikInput
          disabled={!editMode}
          name="mileMarker"
          label="Mile Marker"
          labelId="mileMarker"
          placeholder="Enter mile marker"
        />
      </span>
    );

    if (createMode) {
      return (
        <React.Fragment>
          <TitleRow title="Listing details">
            <Col className="text-right overflow-visible">
              {inProgress ? (
                <Spinner size="sm" className="float-right" />
              ) : (
                <>
                  <Button
                    color="primary"
                    type="button"
                    className="account-listing-header-btn acc-list-primary-btn"
                    onClick={onPrefillForm}
                  >
                    Prefill from the account info
                  </Button>
                  {/*//todo revert once is ready*/}
                  {/*<Button*/}
                  {/*  color="secondary"*/}
                  {/*  type="button"*/}
                  {/*  className="preview-listing-btn account-listing-header-btn"*/}
                  {/*  onClick={onPreviewListing}*/}
                  {/*>*/}
                  {/*  /!* <Eye {...iconProps} className="mr-1" /> *!/*/}
                  {/*  <Icon name="Eye" {...iconProps} />*/}
                  {/*  <span className="ml-1 preview-listing-btn-label">*/}
                  {/*    Preview Listing*/}
                  {/*  </span>*/}
                  {/*</Button>*/}
                </>
              )}
            </Col>
          </TitleRow>

          {!updateListingFromEmail && (
            <>
              <hr />
              <Row>
                <Col>
                  <FormGroup className="mb-1 mt-1">
                    <Field name="excludeRenewalEmails">
                      {({ field: { value, name }, form }) => {
                        const accountExcludeRenewalEmails =
                          this.props.accountInfo?.excludeRenewalEmails;

                        return (
                          <CustomInput
                            defaultChecked={
                              accountExcludeRenewalEmails === 1 || value
                            }
                            type="switch"
                            id="excludeRenewalEmails"
                            inline
                            onChange={(e) => {
                              form.setFieldValue(name, e.target.checked);
                            }}
                            disabled={
                              !havePermissionForRenewalEmailsToggle ||
                              !editMode ||
                              accountExcludeRenewalEmails === 1
                            }
                          >
                            <span className="ml-1 font-size-1p1">
                              Exclude from renewal emails
                            </span>
                          </CustomInput>
                        );
                      }}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          {!updateListingFromEmail && (
            <>
              <Row>
                <Col>
                  <FormGroup className="mb-1">
                    <Field name="excludeDowngrades">
                      {({ field: { value, name }, form }) => {
                        const accountExcludeDowngrades =
                          this.props.accountInfo?.excludeDowngrades;

                        return (
                          <CustomInput
                            defaultChecked={
                              accountExcludeDowngrades === 1 || value
                            }
                            type="switch"
                            id="excludeDowngrades"
                            inline
                            onChange={(e) => {
                              form.setFieldValue(name, e.target.checked);
                            }}
                            disabled={
                              !havePermissionForAutomaticDowngradesToggle ||
                              !editMode ||
                              accountExcludeDowngrades === 1
                            }
                          >
                            <span className="ml-1 font-size-1p1">
                              Exclude from automatic downgrades
                            </span>
                          </CustomInput>
                        );
                      }}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          {!updateListingFromEmail && (
            <Row>
              <Col>
                <FormGroup className="mb-1">
                  <Field name="isItPaidFor">
                    {({ field: { value, name }, form }) => {
                      return (
                        <CustomInput
                          defaultChecked={value}
                          type="switch"
                          id="isItPaidFor"
                          inline
                          onChange={(e) => {
                            form.setFieldValue(name, e.target.checked);
                          }}
                          disabled={!editMode}
                        >
                          <span className="ml-1 font-size-1p1">
                            Is listing paid for?
                          </span>
                        </CustomInput>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          )}

          {!updateListingFromEmail && (
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Field name="isItPaidFor">
                    {() => {
                      return (
                        <>
                          <FormGroup className="mb-1 font-size-1p1 d-flex">
                            <FormikConfirmSwitch
                              id="includeInAuthorizedPromotions"
                              fieldName="includeInAuthorizedPromotions"
                              label="Include in authorized vendors"
                              disabled={
                                !havePermissionForAuthorizedVendorToggle ||
                                !editMode
                              }
                            />
                            {/*<span className="font-size-1p1">*/}
                            {/*  Include in authorized vendors & featured*/}
                            {/*  authorized vendors*/}
                            {/*</span>*/}
                          </FormGroup>
                          <FormGroup className="mb-1 font-size-1p1 d-flex">
                            <FormikConfirmSwitch
                              id="includeInFeaturedVendors"
                              fieldName="includeInFeaturedVendors"
                              label="Include in featured authorized vendors"
                              disabled={
                                !havePermissionForFeaturedAuthorizedVendorToggle ||
                                !editMode
                              }
                            />
                            {/*<span className="font-size-1p1">*/}
                            {/*  Include in authorized vendors & featured*/}
                            {/*  authorized vendors*/}
                            {/*</span>*/}
                          </FormGroup>
                        </>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Divider textColor="primary" position="left">
            About Listing
          </Divider>

          <Row>
            <Col md="6" lg="4">
              {priceId}
            </Col>
            <Col md="6" lg="4">
              {renewalDate}
            </Col>
            <Col md="6" lg="4">
              {createdAt}
            </Col>
          </Row>

          <Divider textColor="primary" position="left">
            Company
          </Divider>

          <Row>
            <Col md="6" xl="6">
              {companyName}
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="4">
              {address}
            </Col>
            <Col md="12" lg="8">
              <Row>
                <Col md="6" lg="4">
                  {city}
                </Col>
                <Col md="6" lg="4">
                  {statesPicker}
                </Col>
                <Col md="6" lg="4">
                  {zip}
                </Col>
              </Row>
            </Col>
            <Col md="6" lg="4">
              {interstate}
            </Col>
            <Col md="6" lg="4">
              {mileMarker}
            </Col>
            <Col md="6" lg="4">
              {adminStatusPicker}
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <div>
        {updateListingFromEmail && (
          <AccountBox
            accountInfo={accountInfo}
            listing
            className="mb-0 mt-0"
            updateListingFromEmail={updateListingFromEmail}
          />
        )}
        <div
          className={`${
            right && changedSections.includes("listingInfo")
              ? "section-changed"
              : right
              ? "section-not-change"
              : null
          }`}
        >
          <TitleRow
            title={listingData?.name + " #" + listingData?.id}
            className="mb-0 mt-0 pt-0"
            isPrivateListing={listingData?.isPrivate}
          >
            <div className="text-right ">
              {editMode && !updateListingFromEmail && (
                <>
                  {/*<Button*/}
                  {/*  color="primary"*/}
                  {/*  type="button"*/}
                  {/*  className="account-listing-header-btn transparent"*/}
                  {/*  onClick={() => {*/}
                  {/*    if (validForm) {*/}
                  {/*      onRemoveListing();*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Trash2 {...iconProps} />*/}
                  {/*  /!* <Icon name="Delete" {...iconProps} /> *!/*/}
                  {/*</Button>*/}
                  {/*//todo revert once is ready */}
                  {/*<Button*/}
                  {/*  color="secondary"*/}
                  {/*  type="button"*/}
                  {/*  className="preview-listing-btn account-listing-header-btn"*/}
                  {/*  onClick={onPreviewListing}*/}
                  {/*>*/}
                  {/*  <Icon name="Eye" {...iconProps} />*/}
                  {/*  <span className="ml-1 preview-listing-btn-label">*/}
                  {/*    Preview Listing*/}
                  {/*  </span>*/}
                  {/*</Button>*/}
                  <>
                    {updateListingSidebar && !this.props.reviewListingData && (
                      <div className="d-flex align-items-center">
                        <span
                          style={{
                            display: "inline-block",
                            marginRight: "1rem",
                          }}
                        >
                          <FTSMenu
                            options={this.options}
                            isOpen={this.state.isOpen}
                            handleMenu={this.handleMenu}
                          />
                        </span>
                        <OnHoldPicker
                          className="mr-1"
                          listingData={listingData}
                          handleSidebar={this.props.handleSidebar}
                        />
                      </div>
                    )}
                    {/* <Button
                      type="button"
                      className="duplicate-listing-btn mr-1"
                      onClick={toggleDuplicateListingModal}
                    >
                      <Icon name="Duplicate" {...iconProps} />
                      <span className="ml-1 duplicate-listing-btn-label">
                        Duplicate listing
                      </span>
                    </Button> */}

                    {/* <Button
                      type="button"
                      className="duplicate-listing-btn mr-1"
                      onClick={toggleReassignListingModal}
                    >
                      <Icon name="Reassign" {...iconProps} />
                      <span className="ml-1 duplicate-listing-btn-label">
                        Reassign listing
                      </span>
                    </Button> */}
                  </>
                  {!updateListingSidebar ? (
                    <>
                      <Button
                        color="tertiary"
                        type="button"
                        className="preview-listing-btn account-listing-header-btn"
                        onClick={() => {
                          toggleEditConfirmationModal();
                        }}
                      >
                        <span className="preview-listing-btn-label">
                          Cancel
                        </span>
                      </Button>
                      <Button
                        color="secondary"
                        type="button"
                        className="mr-1"
                        disabled={!havePermissionForApplyToMultiple}
                        onClick={() => {
                          this.props.validateForm();
                          if (
                            !Object.keys(this.props.formRef.current.errors)
                              .length
                          ) {
                            toggleMultipleListingsModal();
                            saveUpdatePayload(values);
                          } else {
                            toast.error("Unable to proceed. Invalid form!", {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          }
                        }}
                      >
                        <span className="preview-listing-btn-label">
                          Apply to multiple Listings
                        </span>
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        className="account-listing-header-btn acc-list-primary-btn mr-1"
                      >
                        Save changes
                      </Button>
                    </>
                  ) : null}
                </>
              )}

              {!editMode && (
                <>
                  {/*//todo revert this once is ready */}
                  {/*<Button*/}
                  {/*  color="secondary"*/}
                  {/*  type="button"*/}
                  {/*  className="preview-listing-btn account-listing-header-btn"*/}
                  {/*  onClick={onPreviewListing}*/}
                  {/*>*/}
                  {/*  <Icon name="Eye" {...iconProps} />*/}
                  {/*  <span className="ml-1 preview-listing-btn-label">*/}
                  {/*    Preview Listing*/}
                  {/*  </span>*/}
                  {/*</Button>*/}
                  {/*<Button*/}
                  {/*  color="primary"*/}
                  {/*  type="button"*/}
                  {/*  className="account-listing-header-btn transparent"*/}
                  {/*  onClick={() => {*/}
                  {/*    if (validForm) {*/}
                  {/*      onRemoveListing();*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Trash2 {...iconProps} />*/}
                  {/*  /!* <Icon name="Delete" {...iconProps} /> *!/*/}
                  {/*</Button>*/}
                  {!updateListingFromEmail &&
                    !preview &&
                    checkPermissions(
                      [{ id: 2, subCat: [15] }],
                      this.props.adminPermissions,
                    ) && (
                      <span
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                        }}
                      >
                        <FTSMenu
                          options={this.options}
                          isOpen={this.state.isOpen}
                          handleMenu={this.handleMenu}
                        />
                      </span>
                    )}

                  {!preview && (
                    <span className="edit-btn-wrapper mr-1">
                      <div
                        className="edit-btn transparent p-1"
                        disabled={
                          listingLocked ||
                          !checkPermissions(
                            [{ id: 2, subCat: [15] }],
                            this.props.adminPermissions,
                          )
                        }
                        onClick={() => {
                          if (upgradeInProgress) {
                            this.toggleUpgradeInProgressModal();
                          } else {
                            resetForm();
                            onEditToggle();
                          }
                        }}
                      >
                        <Icon name="Edit" size={20} color="#32383A" />
                        <span className="edit-btn-label">Edit</span>
                      </div>
                    </span>
                  )}
                </>
              )}
            </div>
          </TitleRow>
          <span className="listing-info-row">
            <span className="listing-location-info">
              <MapPin size={16} />{" "}
              <span
                className={`${
                  keys && keys.includes("city") && "field-changed"
                }`}
              >
                {`${listingData?.city}`}
              </span>
              <span
                className={`${
                  keys && keys.includes("state") && "field-changed"
                }`}
              >
                <span> </span>
                {`, ${listingData?.state}`}
              </span>
            </span>
            <span
              className={`${
                keys && keys.includes("phoneNumber") && "field-changed"
              }`}
            >
              <Phone size={16} /> {listingData?.phoneNumber}
            </span>
          </span>
        </div>

        {!updateListingFromEmail && !preview && (
          <>
            <hr />
            <Row>
              <Col>
                <FormGroup className="mb-1 mt-1">
                  <Field name="excludeRenewalEmails">
                    {({ field: { value, name }, form }) => {
                      const accountExcludeRenewalEmails =
                        this.props.accountInfo?.excludeRenewalEmails;

                      return (
                        <CustomInput
                          key={accountExcludeRenewalEmails}
                          defaultChecked={accountExcludeRenewalEmails || value}
                          type="switch"
                          id="excludeRenewalEmails"
                          inline
                          onChange={(e) => {
                            form.setFieldValue(name, e.target.checked);
                          }}
                          disabled={
                            !havePermissionForRenewalEmailsToggle ||
                            !editMode ||
                            accountExcludeRenewalEmails === 1
                          }
                        >
                          <span className="ml-1 font-size-1p1">
                            Exclude from renewal emails
                          </span>
                        </CustomInput>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {!updateListingFromEmail && !preview && (
          <>
            <Row>
              <Col>
                <FormGroup className="mb-1">
                  <Field name="excludeDowngrades">
                    {({ field: { value, name }, form }) => {
                      const accountExcludeDowngrades =
                        this.props.accountInfo?.excludeDowngrades ||
                        this.props.accountInfo?.excludeDowngrades;

                      return (
                        <CustomInput
                          key={accountExcludeDowngrades}
                          defaultChecked={
                            accountExcludeDowngrades === 1 || value
                          }
                          type="switch"
                          id="excludeDowngrades"
                          inline
                          onChange={(e) => {
                            form.setFieldValue(name, e.target.checked);
                          }}
                          disabled={
                            !havePermissionForAutomaticDowngradesToggle ||
                            !editMode ||
                            accountExcludeDowngrades === 1
                          }
                        >
                          <span className="ml-1 font-size-1p1">
                            Exclude from automatic downgrades
                          </span>
                        </CustomInput>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {!updateListingFromEmail && !preview && (
          <>
            <Row>
              <Col>
                <FormGroup className="mb-1">
                  <Field name="isItPaidFor">
                    {({ field: { value, name }, form }) => {
                      return (
                        <CustomInput
                          defaultChecked={value}
                          type="switch"
                          id="isItPaidFor"
                          inline
                          onChange={(e) => {
                            form.setFieldValue(name, e.target.checked);
                          }}
                          disabled={!editMode}
                        >
                          <span className="ml-1 font-size-1p1">
                            Is listing paid for?
                          </span>
                        </CustomInput>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {!updateListingFromEmail && !preview && (
          <>
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Field name="isItPaidFor">
                    {() => {
                      return (
                        <>
                          <FormGroup className="mb-1 font-size-1p1 d-flex">
                            <FormikConfirmSwitch
                              id="includeInAuthorizedPromotions"
                              fieldName="includeInAuthorizedPromotions"
                              disabled={
                                !havePermissionForAuthorizedVendorToggle ||
                                !editMode ||
                                !this.props?.accountInfo?.adminIsMerchant
                              }
                            />
                            <span className="font-size-1p1">
                              Include in authorized vendors
                            </span>
                          </FormGroup>
                        </>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Field name="isItPaidFor">
                    {() => {
                      return (
                        <>
                          <FormGroup className="mb-1 font-size-1p1 d-flex">
                            <FormikConfirmSwitch
                              id="includeInFeaturedVendors"
                              fieldName="includeInFeaturedVendors"
                              disabled={
                                !havePermissionForFeaturedAuthorizedVendorToggle ||
                                !editMode ||
                                !this.props?.accountInfo?.adminIsMerchant
                              }
                            />
                            <span className="font-size-1p1">
                              Include in featured authorized vendors
                            </span>
                          </FormGroup>
                        </>
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <div
          className={`${
            right && changedSections.includes("aboutListing")
              ? "section-changed"
              : null
          } about-listing`}
        >
          <Divider textColor="primary" position="left">
            About Listing
          </Divider>

          <Row>
            <Col md="6" lg="2" xl="2">
              {createdAt}
            </Col>
            {listingData?.createdFromIp && (
              <Col md="6" lg="2" xl="2">
                <div className="form-group">
                  <label htmlFor="last_contact_date" className="disabled">
                    Created from:
                  </label>
                  <div id="" className="formik-input-value undefined">
                    <a
                      rel="noreferrer"
                      href={`https://iplocation.io/ip/${listingData.createdFromIp}`}
                      target={"_blank"}
                    >
                      {listingData?.createdFromIp}
                    </a>
                  </div>
                </div>
              </Col>
            )}
            <Col md="6" lg="2" xl="3">
              <span
                className={`${
                  keys && keys.includes("updatedAt") && "field-changed"
                }`}
              >
                <FormikInput
                  disabled
                  name="updatedAt"
                  label="Last Update Date"
                  labelId="updatedAt"
                />
              </span>
            </Col>
            {!updateListingFromEmail && (
              <Col md="6" lg="2" xl="2">
                {!editMode ? (
                  <span
                    className={`${
                      keys && keys.includes("renewalDate") && "field-changed"
                    }`}
                  >
                    {priceID !== 5 ? (
                      <FormikInput
                        disabled={!createMode}
                        name="renewalDate"
                        label="Renewal Date"
                        labelId="renewalDate"
                      />
                    ) : (
                      <span>
                        <FormGroup>
                          <p className="fts-label-disabled">Renewal Date</p>
                          <p className="fts-input-value">No date</p>
                        </FormGroup>
                      </span>
                    )}
                  </span>
                ) : (
                  <span>
                    {priceID !== 5 ? (
                      renewalDate
                    ) : (
                      <FormGroup>
                        <p className="fts-label-disabled">Renewal Date</p>
                        <p className="fts-input-value">No date</p>
                      </FormGroup>
                    )}
                  </span>
                )}
              </Col>
            )}

            <Col md="6" lg="2" xl="3">
              {priceId}
            </Col>
          </Row>
        </div>

        <div
          className={`${
            right && changedSections.includes("company")
              ? "section-changed"
              : null
          }`}
        >
          <Divider textColor="primary" position="left">
            Company
          </Divider>

          <Row>
            <Col>{companyName}</Col>
          </Row>
          <Row>
            <Col md="6" lg="4" xl="3">
              {address}
            </Col>
            <Col md="6" lg="4" xl="3">
              {city}
            </Col>
            <Col md="6" lg="4" xl="3">
              {statesPicker}
            </Col>
            <Col md="6" lg="4" xl="3">
              {zip}
            </Col>
            <Col md="6" lg="4" xl="3">
              {interstate}
            </Col>
            <Col md="6" lg="4" xl="3">
              {mileMarker}
            </Col>
            <Col md="6" lg="4" xl="3">
              {adminStatusPicker}
            </Col>
          </Row>
        </div>
        {isOpenUpgradeInProgressModal && (
          <UpgradeInProgressModal
            isOpen={isOpenUpgradeInProgressModal}
            toggleUpgradeInProgressModal={this.toggleUpgradeInProgressModal}
          />
        )}
      </div>
    );
  }
}
export default memo(ListingInfo);
